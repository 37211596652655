@import "variables";
@import "framework/framework";
@import '../../node_modules/aos/src/sass/aos.scss';

html {
	color: $color-white;
	font-family: 'Berthold Akzidenz Grotesk BE';
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	position: relative;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 13px;
	}

	@include tablet-down {
		font-size: 12px;
	}

	@include phone-down {
		font-size: 11px;
	}

	#side-logo {
		max-height: 95%;
		position: absolute;
		right: 0;
		top: rem(60);
		width: auto;
		z-index: 3;
	}

	#words-1 {
		animation: float 6s ease-in-out infinite;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}

	#words-2 {
		animation: float 6s ease-in-out infinite;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;
	}

	@keyframes float {
		0% {
			transform: translatey(0px) rotate(0deg);
		}
		50% {
			transform: translatey(-40px) rotate(-4deg);
		}
		100% {
			transform: translatey(0px) rotate(0deg);
		}
	}

	@keyframes float2 {
		0% {
			transform: translatey(0px) rotate(0deg);
		}
		50% {
			transform: translatey(-80px) rotate(4deg);
		}
		100% {
			transform: translatey(0px) rotate(0deg);
		}
	}
}

body {
	background: $color-brown url('../img/bg.png') no-repeat center top;
	background-size: cover;
	overflow-x: hidden;
}

a {
	color: $color-white;
	@include hover-focus {
		color: $color-white;
	}
}

header {
	padding: rem(50) 0;
	position: relative;
	text-align: center;
	z-index: 10;

	h1 {
		margin: 0 0 rem(40) 0;

		> img {
			margin: 0 auto;
		}
	}

	.socials {
		list-style: none;
		margin: 0;
		padding: 0;

		> li {
			display: inline-block;
			vertical-align: middle;

			+ li {
				margin-left: rem(12);
			}

			> a {
				font-size: rem(24);
				opacity: 1;

				@include hover-focus {
					opacity: 0.8;
				}
			}
		}
	}
}

main {
	#cta {
		position: relative;
		text-align: center;
		z-index: 10;

		h2 {
			font-family: 'Berthold Akzidenz-Grotesk';
			font-size: rem(25);
			font-weight: bold;
			margin: 0 0 rem(30) 0;
		}

		img {
			box-shadow: 1px 1px 90px 0px rgba(0, 0, 0, 0.75);
			margin: 0 auto rem(40) auto;
		}

		span,
			/*a*/
		{
			border-bottom: rem(5) solid $color-white;
			display: inline-block;
			font-family: 'Berthold Akzidenz-Grotesk';
			font-size: rem(25);
			font-weight: bold;
			padding-bottom: rem(10);
			vertical-align: middle;
		}

		a {
			opacity: 1;
			@include hover-focus {
				opacity: 0.8;
			}
		}

		.buy {
			list-style: none;
			margin: rem(30) 0 rem(30) 0;
			padding: 0;

			> li {
				display: inline-block;
				padding: rem(5);
				vertical-align: middle;

				> a {
					display: inline-block;
					vertical-align: middle;

					> img {
						margin: 0;
					}
				}
			}
		}

		.releases {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			margin: rem(75) auto 0 auto;
			max-width: rem(600);

			> div {
				flex: 0 0 50%;
				padding: 0 15px;

				> a {
					border-bottom: 0;
					display: block;
					padding-bottom: 0;

					> img {
						box-shadow: none;
						margin: 0 auto;
						width: 100%;
					}
				}


			}
		}
	}

	#video {
		padding: rem(75) 0 0 0;
		position: relative;
		z-index: 10;
	}

	#newsletter {
		padding: rem(75) 0 0 0;
		position: relative;
		z-index: 10;

		.content {
			align-items: center;
			background: $color-brown-2 url('../img/newsletter-bg.png') no-repeat left top;
			background-size: cover;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-end;
			min-height: rem(650);
			padding: rem(100);

			@include phone-down {
				padding: rem(75);
			}

			> div {
				flex: 0 1 auto;

				@include phone-down {
					width: 100%;
				}

				> h2 {
					font-family: 'Berthold Akzidenz-Grotesk';
					font-size: rem(25);
					font-weight: bold;
					margin: 0 0 rem(40) 0;
				}

				> form {
					max-width: 100%;
					width: rem(350);

					@include phone-down {
						width: 100%;
					}

					> div {

						+ div {
							margin-top: rem(40);
						}

						> input {
							background: none;
							border: 0;
							border-bottom: rem(5) solid $color-white;
							border-radius: 0;
							color: $color-white;
							display: block;
							letter-spacing: letter-spacing(100);
							padding: 0 0 rem(5) 0;
							text-transform: uppercase;
							width: 100%;

							@include placeholder {
								color: $color-white;
							}

							&:focus {
								outline: 0;
							}
						}

						> button {
							background: none;
							border: 0;
							border-bottom: rem(5) solid $color-white;
							border-radius: 0;
							color: $color-white;
							display: inline-block;
							font-family: 'Berthold Akzidenz-Grotesk';
							font-size: rem(25);
							font-weight: bold;
							line-height: 1;
							opacity: 1;
							padding-bottom: rem(10);
							text-transform: uppercase;
							vertical-align: middle;

							@include hover-focus {
								color: $color-white;
								opacity: 0.8;
								outline: 0;
							}
						}
					}
				}
			}
		}
	}
}

footer {
	padding: rem(75);
	position: relative;
	z-index: 10;

	.copyright {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;

		@include phone-down {
			display: block;
			text-align: center;
		}

		> a {
			display: inline-block;
			flex: 0 0 auto;
			max-width: rem(150);
			opacity: 1;
			vertical-align: middle;

			@include hover-focus {
				opacity: 0.8;
			}

			+ a {
				margin-left: rem(30);
			}
		}

		> div {
			flex: 0 1 auto;
			font-size: rem(12);
			letter-spacing: letter-spacing(100);
			line-height: line-height(20, 12);
			padding-left: rem(30);
			text-indent: text-indent(100);

			@include phone-down {
				margin-top: rem(30);
				padding-left: 0;
			}

			p {
				margin: 0;
			}
		}
	}
}