@font-face {
	font-family: 'Berthold Akzidenz-Grotesk';
	src: url('../font/AkzidenzGroteskBE-BoldCn.woff2') format('woff2'),
	url('../font/AkzidenzGroteskBE-BoldCn.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Berthold Akzidenz Grotesk BE';
	src: url('../font/AkzidenzGroteskBE-Regular.woff2') format('woff2'),
	url('../font/AkzidenzGroteskBE-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

$html-font-size: 14;
$color-white: #fff;
$color-black: #000;
$color-brown: #231e16;
$color-brown-2: #221610;